
import { pad } from 'highcharts';
import Vue from 'vue'

export default Vue.extend({
    name: 'DiscoverYourMatches',
    components: {
    },
    computed: {
        linkedinMatchUrl() {
            return process.env.VUE_APP_NAMECARD_LINKEDIN_MATCH_URL_YC;
        }
    },
    data(){
        return{
            backgroundImage: require('@/assets/LocateLead/background.png'),
        }
    },
    methods: {
    },
    mounted() {
    }
})
