
import Vue from 'vue'
import Hero from '@/components/Hero.vue';
import TrustedBy from '@/components/TrustedBy.vue';
import MainLayout from '@/components/MainLayout.vue';
import Features from '@/components/Features.vue';
import UseCases from '@/components/UseCases.vue';
import LocateLead from '@/components/LocateLead.vue';
import JoinUs from '@/components/JoinUs.vue';

export default Vue.extend({
    name: 'Home',
    components: {
        Hero,
        TrustedBy,
        Features,
        MainLayout,
        UseCases,
        LocateLead,
        JoinUs
    },
    mounted() {
     this.$nextTick(() => {
            window.scrollTo({
                top: 0,
                behavior: "smooth", // 如果不需要平滑滾動，移除此行
            });
        });
    }
});
