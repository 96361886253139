
import Vue from 'vue'

export default Vue.extend({
    name: 'Features',
    components: {
    },
    computed: {
        namecardWSUrlCES() {
            return process.env.VUE_APP_NAMECARD_WORKSPACE_URL_CES;
        },
        cesNonLoginUrl() {
            return process.env.VUE_APP_CES_NON_LOGIN_URL;
        }
    },
    data(){
        return{
        }
    },
    methods: {
    },
    mounted() {
    }
})
